<template>
  <div>
    <portal to="popup-inser-email">
      <PopupInsertEmail
        v-if="showModal"
        @emailSaved="sendPdf"
        @closeModal="showModal = false"
      ></PopupInsertEmail>
    </portal>
    <div
      class="flex"
      :class="[
        !is_outside_map
          ? 'flex-col space-y-1'
          : 'sm:flex-col space-x-3 sm:space-x-0 sm:space-y-1',
      ]"
    >
      <pro-button
        class="mb-2 justify-between"
        btn="primary"
        icon
        :round="!is_mobile_and_out_side_map"
        @click="downloadPdf"
        v-tooltip.left="{
          content: 'Téléchargez PDF',
          classes: [...classes, is_mobile_and_out_side_map ? 'hidden' : ''],
        }"
      >
        <p class="sm:flex hidden" v-if="is_outside_map">Téléchargez PDF</p>
        <ProIcon name="download" color="white" />
      </pro-button>
      <pro-button
        class="mb-2 justify-between"
        btn="primary"
        icon
        :round="!is_mobile_and_out_side_map"
        @click="sendPdf"
        v-tooltip.left="{
          content: 'Envoyez par email',
          classes: [...classes, is_mobile_and_out_side_map ? 'hidden' : ''],
        }"
      >
        <p class="sm:flex hidden" v-if="is_outside_map">Envoyez par email</p>
        <ProIcon name="send" color="white" />
      </pro-button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    is_outside_map: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      classes: [
        'text-promy-green-350',
        'mr-2',
        'px-3',
        'py-2',
        'z-50',
        'rounded-box',
        'shadow',
        'text-xs',
        'bg-white',
        'mb-2',
      ],
      withoutPortal: false,
      showModal: false,
    }
  },

  computed: {
    ...mapGetters({
      address: 'address/address',
      user: 'auth/user',
      infos_commune: 'address/infos_commune',
    }),
    is_mobile_and_out_side_map() {
      return this.$mq === 'sm' && this.is_outside_map
    },
  },
  methods: {
    ...mapMutations({
      toggleGuestModal: 'auth/TOGGLE_GUEST_MODAL',
    }),
    checkParcelles() {
      let code_insee = this.infos_commune.code_insee
      let center = {
        long: this.address.information.geometry.coordinates[1],
        lat: this.address.information.geometry.coordinates[0],
      }

      return {
        code_insee,
        center,
      }
    },
    downloadPdf() {
      this.toggleGuestModal()
      this.toast('Récapitulatif pdf', 'En cours de téléchargement', 'progress')

      const { code_insee, center } = this.checkParcelles()

      this.$http
        .get(`/grand-publics/pdfGu/${this.address.uuid}`, {
          params: {
            download: true,
            code_insee: code_insee,
            lat: center[1],
            long: center[0],
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          this.toast('', '', 'clear')
          let blob = new Blob([response.data], {
            type: 'application/pdf',
          })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.setAttribute(
            'download',
            `${this.address.information.properties.label.replace(
              /\s/g,
              '-',
            )}.pdf`,
          )

          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          this.toast('', '', 'clear')
          var enc = new TextDecoder('utf-8')
          var arr = new Uint8Array(err.response.data)
          let error = JSON.parse(enc.decode(arr)).error

          if ((err.response.status = 429)) {
            this.toast(
              'Erreur',
              'Une erreur est survenue lors de la récupération des informations.',
              'error',
            )
          }

          if (err.response.status === 422) {
            this.toast(
              'Téléchargement pdf',
              err.response.data.message,
              'warning',
            )
          }
        })
    },
    sendPdf() {
      this.toggleGuestModal()
      if (!this.user.email) {
        this.showModal = true
        return
      }
      this.showModal = false

      const { code_insee, center } = this.checkParcelles()

      this.$http
        .get(`/grand-publics/pdfGu/${this.address.uuid}`, {
          params: {
            code_insee: code_insee,
            lat: center[1],
            long: center[0],
          },
        })
        .then(() => {
          this.toast(
            'Envoi pdf',
            'Le document a été envoyé par Email',
            'success',
          )
        })
        .catch((err) => {
          if (err.response.status === 429) {
            this.toast('Envoi pdf', err.response.data.error, 'warning')
          }
          if (err.response.status === 422) {
            this.toast('Envoi pdf', err.response.data.message, 'warning')
          }
        })
    },
  },
  beforeDestroy() {
    this.withoutPortal = true
  },
}
</script>
